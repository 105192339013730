import axios from 'axios'
const { baseURL } = require('@/assets/js/constant')
const $http = axios.create({
    baseURL
})
const state = {
    user: [],
    isLoggedIn: localStorage.getItem('isLoggedIn') ? localStorage.getItem('isLoggedIn') : false,

}

const getters = {
    IsLoggedIn(state) {
        return state.isLoggedIn
    }

}

const actions = {
    async checkUserLoggedIn({ commit }, user) {
        const event_formdata = new FormData()
        for (const key in user) {
            event_formdata.append(key, user[key])
        }
        const extractedData = await $http.post('/adminUserLogin', event_formdata);
        if (!extractedData.data.ad_logged_in) {
            throw new Error();
        }
        commit('SET_USER_LOGIN', extractedData.data.ad_logged_in)

    },
    async userSignUp({ commit }, user) {
        const event_formdata = new FormData()
        for (const key in user) {
            event_formdata.append(key, user[key])
        }
        await $http.post('/addAdminUser', event_formdata);

    },
    logoutUser({ commit }) {
        commit('DELETE_TOKEN')
    },

}

const mutations = {

    DELETE_TOKEN: (state) => {
        state.isLoggedIn = false
        localStorage.removeItem('isLoggedIn')
    },
    SET_USER_LOGIN: (state, isLog) => {
        state.isLoggedIn = isLog
        localStorage.setItem('isLoggedIn', isLog)
    }

}

export default {
    state,
    getters,
    mutations,
    actions
}
