// This is a component by which we emit the username and password field for SignUp
<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <!-- Email field  -->
      <v-text-field
        required
        :rules="emailRules"
        v-model="email"
        label="Email"
        prepend-icon="mdi-email"
        type="text"
      ></v-text-field>
      <!-- Password field  -->
      <v-text-field
        required
        :rules="[(v) => !!v || 'Password is required']"
        v-model="password"
        prepend-icon="mdi-lock"
        :type="hidePassword ? 'password' : 'text'"
        :append-icon="hidePassword ? 'mdi-eye-off' : 'mdi-eye'"
        label="Password"
        @click:append="hidePassword = !hidePassword"
      />
      <v-text-field
        v-model="passCode"
        :rules="[(v) => !!v || 'Pass code is required']"
        required
        label="Pass Code"
        name="Code"
        prepend-icon="mdi-key"
        type="text"
      ></v-text-field>
    </v-form>
    <v-btn class="mt-2" block rounded color="primary" dark @click="signUp()"
      >Sign Up</v-btn
    >
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      valid: true,
      passCode: "",
      email: "",
      password: "",

      hidePassword: true,

      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
    };
  },
  methods: {
    signUp() {
      if (this.$refs.form.validate()) {
        const user_detail = {
          email: this.email,
          password: this.password,
        };
        // Emitting data to main Login Page
        if (this.passCode == "green") {
          this.$emit("submit1", user_detail);
        }
      }
    },
  },
  computed: {
    ...mapGetters([""]),
  },
};
</script>
