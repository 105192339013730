<template>
  <div>
    <!-- Appbar of the classroom navigation page  -->
    <v-app-bar
      app
      absolute
      class="v-bar--underline"
      color="transparent"
      height="70"
      flat
    >
      <!-- <v-app-bar-nav-icon class="hidden-md-and-down" @click="drawer = !drawer" /> -->
      <v-btn
        class="ml-3 mr-4"
        elevation="1"
        fab
        small
        @click="drawer = !drawer"
      >
        <v-icon>
          {{ drawer ? "mdi-dots-vertical" : "mdi-format-list-bulleted" }}
        </v-icon>
      </v-btn>
      <v-toolbar-title class="font-weight-light text-h5"
        >AnyaGreenEnergy</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <!-- <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" dark @click="toggleDark()">{{
            isDark == "dark" ? "mdi-white-balance-sunny" : "mdi-weather-night"
          }}</v-icon>
        </template>
        <span>Day/ Night mode</span>
      </v-tooltip> -->
    </v-app-bar>
    <!-- Navigation Drawer of Navigation Page -->
    <v-navigation-drawer
      v-model="drawer"
      :right="$vuetify.rtl"
      src="https://ae-solar.com/wp-content/uploads/2020/04/solar-cropped.jpg"
      app
      dark
      width="260"
    >
      <v-list-item class="my-2">
        <v-list-item-title>
          <v-img :src="require('@/assets/appLogo.png')" />
        </v-list-item-title>
      </v-list-item>

      <v-divider></v-divider>

      <v-list expand nav>
        <v-list-item-group>
          <v-list-item 
            v-for="item in items"
            :key="item.title"
            link
            active-class="primary white--text"
            class="px-3"
            :to="item.props"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn block class="error" @click="logoutUserButton()">
            Logout
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      drawer: true,
      profile_model: false,
      profile_isedit: false,
      items: [
        { title: "Dashboard", icon: "mdi-view-dashboard", props: "/" },
        { title: "Sliders", icon: "mdi-image", props: "/sliders" },
        { title: "Blogs", icon: "mdi-post", props: "/blogs" },
        {
          title: "Popular Items",
          icon: "mdi-solar-panel-large",
          props: "/popular_items",
        },
        {
          title: "Categories",
          icon: "mdi-shape-outline",
          props: "/categories",
        },
        {
          title: "Testimonials",
          icon: "mdi-card-bulleted-outline",
          props: "/testimonials",
        },
        {
          title: "Reviews",
          icon: "mdi-message-text",
          props: "/reviews",
        },
        {
          title: "Dealership",
          icon: "mdi-account-group-outline",
          props: "/dealership",
        },
        { title: "Carts", icon: "mdi-cart", props: "/carts" },
        {
          title: "Orders",
          icon: "mdi-file-document-multiple",
          props: "/orders",
        },
      ],
    };
  },
  props: {
    name: String,
  },
  methods: {
    ...mapActions(["logoutUser"]),
    logoutUserButton() {
      this.logoutUser();
    },
  },
  computed: {
    ...mapGetters([""]),
  },
  async created() {
    // this.fetchClassrooms(this.userToken);
    // this.$vloading.show();
    // await this.loadProfile({ token: this.userToken });
    // this.prof.introduction = this.myProfile.introduction
    //   ? this.myProfile.introduction
    //   : "";
    // this.$vloading.hide();
  },
};
</script>

<style lang="sass">
.v-list-item
  margin-bottom: 8px

.v-list-item::before,
.v-list-item::after
  display: none

.v-list-group__header__prepend-icon,
.v-list-item__icon
  margin-top: 12px
  margin-bottom: 12px
  margin-left: 4px
</style>

