import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#4CAF50',
                secondary: '#00008B',
                accent: '#e91e63',
                info: '#00CAE3',
                success: '#4CAF50',
                warning: '#FB8C00',
                error: '#FF5252',
            },
        },
    },
});
