import axios from "axios";
const { baseURL } = require("@/assets/js/constant");
const $http = axios.create({
  baseURL,
});
const state = {
  dealerships: [],
};

const getters = {
  GetAllDealership(state) {
    return state.dealerships;
  },
};

const actions = {
  async fetchAllDealership({ commit }) {
    const extractedData = await $http.get("/getAllDealer");
    console.log(extractedData.data);
    commit("SET_DEALERSHIP", extractedData.data);
  },
  async getCurrentDealershipPrice({ commit }) {
    try {
        const extractedData = await $http.get("/getDealerPrice");
        return Promise.resolve(extractedData.data);
      } catch (error) {
        console.log(error);
      }
  },

  async updateDealership({ commit }, data) {
    try {
      console.log(data);
      const event_formdata = new FormData();
      for (const key in data) {
        event_formdata.append(key, data[key]);
      }
      const extractedData = await $http.post("/updateDealer", event_formdata);
      commit("UPDATE_DEALERSHIP", data);
    } catch (error) {
      console.log(error);
    }
  },
  async updateCurrentDealershipPrice({ commit }, data) {
    try {
      console.log(data);
      const event_formdata = new FormData();
      for (const key in data) {
          event_formdata.append(key, data[key]);
        }
        const extractedData = await $http.post("/updateDealerPrice", event_formdata);
        return Promise.resolve(extractedData.data);
    } catch (error) {
      console.log(error);
    }
  },
  async deleteDealer({ commit }, id) {
      try {
          await $http.get(`/deleteDealer?dealerId=${id}`
          );
          commit('DELETE_DEALERSHIP', id)
      } catch (error) {
          console.log(error);
      }
  },
};

const mutations = {
  SET_DEALERSHIP: (state, dealerships) => {
    state.dealerships = dealerships;
  },
  UPDATE_DEALERSHIP: (state, dealership) => {
    let dealer_index = state.dealerships.findIndex(
      (item) => item.id === dealership.id
    );
    Object.assign(state.dealerships[dealer_index], dealership);
  },
  DELETE_DEALERSHIP: (state, id) => {
      const index = state.dealerships.findIndex(cat => cat.id == id)
      state.dealerships.splice(index, 1)
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
