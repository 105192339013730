import axios from 'axios'
const { baseURL } = require('@/assets/js/constant')
const $http = axios.create({
    baseURL
})
const state = {
    orders: [],
    order: [],

}

const getters = {
    GetOrders(state) {
        return state.orders
    },
    GetOrder(state) {
        return state.order
    }

}

const actions = {
    async fetchAllOrders({ commit }) {
        const extractedData = await $http.get('/getOrders');
        commit('SET_ORDERS', extractedData.data)


    },
    async fetchOrder({ commit }, id) {
        const extractedData = await $http.get(`/getOrderDetails?orderId=${id}`);
        commit('SET_ORDER', extractedData.data)


    },

    // async deleteBlog({ commit }, id) {
    //     try {
    //         await $http.get(`/deleteBlog?blogId=${id}`
    //         );
    //         commit('DELETE_BLOG', id)
    //     } catch (error) {
    //         console.log(error);
    //     }
    // },
    // async updateBlog({ commit }, data) {
    //     const event_formdata = new FormData()
    //     for (const key in data) {
    //         event_formdata.append(key, data[key])
    //     }
    //     try {
    //         await $http.post('/updateBlog', event_formdata);
    //         // commit('UPDATE_BLOG', data)
    //     } catch (error) {
    //         console.log(error);
    //     }


}

const mutations = {
    // ADD_BLOG: (state, blog) => {
    //     state.blogs.push(blog);
    // },
    // DELETE_BLOG: (state, id) => {
    //     const index = state.blogs.findIndex(blog => blog.id == id)
    //     state.blogs.splice(index, 1)
    // },
    SET_ORDERS: (state, orders) => {
        state.orders = orders
    },
    SET_ORDER: (state, order) => {
        state.order = order
    }

}

export default {
    state,
    getters,
    mutations,
    actions
}
