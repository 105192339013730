import axios from 'axios'
const { baseURL } = require('@/assets/js/constant')
const $http = axios.create({
    baseURL
})
const state = {
    categories: [],

}

const getters = {
    GetCategories(state) {
        return state.categories
    }

}

const actions = {
    async fetchAllCategories({ commit }) {
        const extractedData = await $http.get('/getAllCategory');
        commit('SET_CATEGORIES', extractedData.data)
    },

    async addCategory({ commit }, data) {
        const event_formdata = new FormData()
        for (const key in data) {
            event_formdata.append(key, data[key])
        }
        const extractedData = await $http.post('/addCategory', event_formdata);
        if (extractedData.data.error) {
            throw new Error(extractedData.data.error);
        }
        commit('ADD_CATEGORY', extractedData.data)
    },
    async deleteCategory({ commit }, id) {
        try {
            await $http.get(`/deleteCategory?catId=${id}`
            );
            commit('DELETE_CATEGORY', id)
        } catch (error) {
            console.log(error);
        }
    },
    async updateCategory({ commit }, data) {
        const event_formdata = new FormData()
        for (const key in data) {
            event_formdata.append(key, data[key])
        }
        try {
            const extractedData = await $http.post('/updateCategory', event_formdata);
            if (extractedData.data.error) {
                throw new Error(extractedData.data.error);
            }
            commit('UPDATE_CATEGORY', extractedData.data)
        } catch (error) {
            console.log(error);
        }

    },
}

const mutations = {
    ADD_CATEGORY: (state, category) => {
        state.categories.push(category);
    },
    SET_CATEGORIES: (state, events) => {
        state.categories = events
    },
    DELETE_CATEGORY: (state, id) => {
        const index = state.categories.findIndex(cat => cat.id == id)
        state.categories.splice(index, 1)
    },
    UPDATE_CATEGORY: (state, cat) => {
        let cat_index = state.categories.findIndex(item => item.id === cat.id)
        Object.assign(state.categories[cat_index], cat)
    },

}

export default {
    state,
    getters,
    mutations,
    actions
}
