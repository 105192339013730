import axios from 'axios'
const { baseURL } = require('@/assets/js/constant')
const $http = axios.create({
    baseURL
})
const state = {
    testimonials: [],

}

const getters = {
    GetTestimonials(state) {
        return state.testimonials
    }

}

const actions = {
    async fetchAllTestimonials({ commit }) {
        const extractedData = await $http.get('/getTestimonial');
        console.log(extractedData.data)
        commit('SET_TESTIMONIALS', extractedData.data)
    },

    async addTestimonial({ commit }, data) {
        const event_formdata = new FormData()
        for (const key in data) {
            event_formdata.append(key, data[key])
        }
        const extractedData = await $http.post('/addTestimonial', event_formdata);
        if (extractedData.data.error) {
            throw new Error(extractedData.data.error);
        }
        commit('ADD_TESTIMONIAL', extractedData.data)
    },
    async updateTestimonial({ commit }, data) {
        const event_formdata = new FormData()
        for (const key in data) {
            event_formdata.append(key, data[key])
        }
        try {
            const extractedData = await $http.post('/updateTestimonial', event_formdata);
            if (extractedData.data.error) {
                throw new Error(extractedData.data.error);
            }
            commit('UPDATE_TESTIMONIAL', extractedData.data)
        } catch (error) {
            console.log(error);
        }

    },
    async deleteCategory({ commit }, id) {
        try {
            await $http.get(`/deleteCategory?catId=${id}`
            );
            commit('DELETE_CATEGORY', id)
        } catch (error) {
            console.log(error);
        }
    },

}

const mutations = {
    ADD_TESTIMONIAL: (state, testimonial) => {
        state.testimonials.push(testimonial);
    },
    SET_TESTIMONIALS: (state, events) => {
        state.testimonials = events
    }, 
    UPDATE_TESTIMONIAL: (state, testi) => {
        let testi_index = state.testimonials.findIndex(item => item.id === testi.id)
        Object.assign(state.testimonials[testi_index], testi)
    },
    DELETE_CATEGORY: (state, id) => {
        const index = state.testimonials.findIndex(cat => cat.id == id)
        state.testimonials.splice(index, 1)
    },


}

export default {
    state,
    getters,
    mutations,
    actions
}
