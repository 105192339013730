<template>
  <v-container class="fill-height a" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="9" md="5">
        <!-- Signu Up component  -->

        <!-- Logged In component  -->
        <v-card style="height: 100%; padding: 10px">
          <v-img
            class="mx-auto"
            src="@/assets/appLogo.png"
            contain
            height="100"
          ></v-img>
          <h1 class="text-center font-weight-light mt-2">
            {{ login ? "Sign Up" : "Sign In" }}
          </h1>

          <v-card-text>
            <sign-up v-if="login" @submit1="signUp"></sign-up>
            <Login v-else @submit="loggedIn"></Login>
          </v-card-text>

          <p class="text-center pb-4 body-2">
            {{
              login ? "Already have an account?" : "Create your account here - "
            }}
            <span class="text ml-1" @click="login = !login">{{
              login ? "Login" : "Create account"
            }}</span>
          </p>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Login from "../components/Login";
import SignUp from "../components/SignUp";
export default {
  data() {
    return {
      login: false,
    };
  },
  components: {
    Login,
    SignUp,
  },
  methods: {
    ...mapActions(["checkUserLoggedIn", "userSignUp"]),
    loggedIn(user) {
      this.$vloading.show();
      this.checkUserLoggedIn(user)
        .then((res) => {
          this.$vloading.hide();
          this.$toasted.show("Successfully Login! ", {
            type: "success",
            duration: 3000,
            position: "top-center",
            theme: "bubble",
            icon: "mdi-account-check",
            iconPack: "mdi",
          });
        })
        .catch((err) => {
          this.$vloading.hide();
          this.$toasted.show("Invalid Username or Password", {
            type: "error",
            duration: 3000,
            position: "top-center",
            theme: "bubble",
            icon: "mdi-account-alert",
            iconPack: "mdi",
          });
        });
    },
    signUp(user) {
      this.$vloading.show();
      this.userSignUp(user)
        .then((res) => {
          this.$vloading.hide();
          this.$toasted.show("User created Successfully", {
            type: "success",
            duration: 3000,
            position: "top-center",
            theme: "bubble",
            icon: "mdi-account",
            iconPack: "mdi",
          });
          this.login = false;
        })
        .catch((err) => {
          this.$vloading.hide();
          this.$toasted.show(err, {
            type: "error",
            duration: 3000,
            position: "top-center",
            theme: "bubble",
            icon: "mdi-account-alert",
            iconPack: "mdi",
          });
        });
    },
  },
  computed: {
    ...mapGetters([""]),
  },
};
</script>

<style>
.a {
  background-image: url(../assets/background.svg);
  background-size: cover;
}
</style>