import axios from "axios";
const { baseURL } = require("@/assets/js/constant");
const $http = axios.create({
  baseURL,
});
const state = {
  catProducts: [],
  extraCatProductImages: [],
  otherData: [],
  catProduct: null,
};

const getters = {
  CatProducts(state) {
    return state.catProducts;
  },
  CatProduct(state) {
    return state.catProduct;
  },
  ExtraCatProductImages(state) {
    return state.extraCatProductImages;
  },
  getOthersData(state) {
    return state.otherData;
  },
};

const actions = {
  async fetchProductsByCatId({ commit }, catId) {
    const extractedData = await $http.get(
      `/getProductByCategory?catId=${catId}`
    );
    commit("SET_CATEGORY_PRODUCTS", extractedData.data);
  },
  async fetchProductDetailByProdId({ commit }, productId) {
    const extractedData = await $http.get(
      `/getProductDetailsByProductId?productId=${productId}`
    );
    commit("SET_PRODUCT_DETAIL", extractedData.data);
  },
  async fetchOtherDetails({ commit }, productId) {
    const extractedData = await $http.get(
      `/getProductProp?productId=${productId}`
    );
    commit("SET_OTHERS_DATA", extractedData.data);
    return Promise.resolve(extractedData.data);
  },
  async addOtherData({ commit }, data) {
    const extractedData = await $http.get(
      `/addProdProp?name=${data.name}&value=${data.value}&productId=${data.productId}`
    );
    commit("ADD_OTHER_DATA", extractedData.data);
  },

  async addProduct({ commit }, data) {
    const event_formdata = new FormData();
    for (const key in data) {
      event_formdata.append(key, data[key]);
    }
    const extractedData = await $http.post("/addProduct", event_formdata);
    if (extractedData.data.error) {
      throw new Error(extractedData.data.error);
    }
    commit("ADD_PRODUCT", extractedData.data);
  },

  async addProductDetail({ commit }, data) {
    console.log(data);
    const event_formdata = new FormData();
    for (const key in data) {
      // if (key === "others") {
      //   //   // append nested object
      //   for (let previewKey in data[key]) {
      //     // event_formdata.append(previewKey, data[key][previewKey]);
      //     console.log('check')
      //     console.log(previewKey)
      //     console.log('check')
      //   }
      // } else {
      // }
      event_formdata.append(key, data[key]);
    }
    // for (var pair of event_formdata.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }
    const extractedData = await $http.post(
      "/addProductDetails",
      event_formdata
    );
    console.log(extractedData.data);
    commit("SET_PRODUCT_DETAIL", extractedData.data);
  },

  async updateProduct({ commit }, data) {
    const event_formdata = new FormData();
    for (const key in data) {
      event_formdata.append(key, data[key]);
    }
    try {
      const extractedData = await $http.post("/updateProduct", event_formdata);
      if (extractedData.data.error) {
        throw new Error(extractedData.data.error);
      }
      commit("UPDATE_PRODUCT", extractedData.data);
    } catch (error) {
      console.log(error);
    }
  },
  async updateProductDetail({ commit }, data) {
    const event_formdata = new FormData();
    for (const key in data) {
      event_formdata.append(key, data[key]);
    }
    try {
      const extractedData = await $http.post(
        "/updateProductDetails",
        event_formdata
      );
      if (extractedData.data.error) {
        throw new Error(extractedData.data.error);
      }
      commit("SET_PRODUCT_DETAIL", extractedData.data);
    } catch (error) {
      console.log(error);
    }
  },

  async deleteProduct({ commit }, id) {
    try {
      await $http.get(`/deleteProduct?productId=${id}`);
      commit("DELETE_PRODUCT", id);
    } catch (error) {
      console.log(error);
    }
  },
  async deleteOtherData({ commit }, id) {
    try {
      await $http.get(`/deleteProdProp?id=${id}`);
      commit("DELETE_OTHER", id);
    } catch (error) {
      console.log(error);
    }
  },
  async deleteExtraImage({ commit }, id) {
    try {
      const event_formdata = new FormData();

      event_formdata.append("id", id);

      const extractedData = await $http.post(
        "/deleteProductImage",
        event_formdata
      );
      console.log(extractedData.data);
      commit("DELETE_EXTRA_PRODUCT_IMAGE", id);
    } catch (error) {
      console.log(error);
    }
  },

  async fetchProductImagesById({ commit }, prodId) {
    const event_formdata = new FormData();

    event_formdata.append("product_id", prodId);

    const extractedData = await $http.post(
      "/getProductImageByProductId",
      event_formdata
    );
    commit("SET_EXTRA_PRODUCT_IMAGES", extractedData.data);
  },

  async addExtraImage({ commit }, data) {
    const event_formdata = new FormData();
    for (const key in data) {
      event_formdata.append(key, data[key]);
    }
    try {
      const extractedData = await $http.post(
        "/addProductImage",
        event_formdata
      );

        commit("ADD_EXTRA_IMAGE", extractedData.data);
    } catch (error) {
      console.log(error);
    }
  },
};

const mutations = {
  SET_CATEGORY_PRODUCTS: (state, categoryProducts) => {
    state.catProducts = categoryProducts;
  },
  SET_OTHERS_DATA: (state, data) => {
    state.otherData = data;
  },
  SET_PRODUCT_DETAIL: (state, product) => {
    state.catProduct = product;
  },
  SET_EXTRA_PRODUCT_IMAGES: (state, images) => {
    state.extraCatProductImages = images;
  },
  ADD_PRODUCT: (state, product) => {
    state.catProducts.push(product);
  },
  ADD_OTHER_DATA: (state, data) => {
    state.otherData.push(data);
  },
  ADD_EXTRA_IMAGE: (state, data) => {
    state.extraCatProductImages.push(data);
  },
  DELETE_PRODUCT: (state, id) => {
    const index = state.catProducts.findIndex((product) => product.id == id);
    state.catProducts.splice(index, 1);
  },
  DELETE_OTHER: (state, id) => {
    const index = state.otherData.findIndex((other) => other.id == id);
    state.otherData.splice(index, 1);
  },
  DELETE_EXTRA_PRODUCT_IMAGE: (state, id) => {
    const index = state.extraCatProductImages.findIndex(
      (product) => product.id == id
    );
    state.extraCatProductImages.splice(index, 1);
  },
  UPDATE_PRODUCT: (state, product) => {
    let prod_index = state.catProducts.findIndex(
      (item) => item.id === product.id
    );
    Object.assign(state.catProducts[prod_index], product);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
