import axios from 'axios'
const { baseURL } = require('@/assets/js/constant')
const $http = axios.create({
    baseURL
})
const state = {
    popularCategories: [],

}

const getters = {
    GetpopularCategories(state) {
        return state.popularCategories
    }

}

const actions = {
    async fetchAllpopularCategories({ commit }) {
        const extractedData = await $http.get('/getPopularCategory');
        commit('SET_POPULAR_CATEGORIES', extractedData.data)
    },

    async deletePopularCategory({ commit }, id) {
        try {
            await $http.get(`/deletePopularCategory?catId=${id}`
            );
            commit('DELETE_POPULAR_CATEGORY', id)
        } catch (error) {
            console.log(error);
        }
    },

    async updatePopularCategory({ commit }, data) {
        const event_formdata = new FormData()
        for (const key in data) {
            event_formdata.append(key, data[key])
        }
        try {
            const extractedData = await $http.post('/updatePopularCategory', event_formdata);
            if (extractedData.data.error) {
                throw new Error(extractedData.data.error);
            }
            commit('UPDATE_POPULAR_CATEGORY', extractedData.data)
        } catch (error) {
            console.log(error);
        }

    },
}

const mutations = {

    SET_POPULAR_CATEGORIES: (state, categories) => {
        state.popularCategories = categories
    },
    DELETE_POPULAR_CATEGORY: (state, id) => {
        const index = state.popularCategories.findIndex(cat => cat.id == id)
        state.popularCategories.splice(index, 1)
    },
    UPDATE_POPULAR_CATEGORY: (state, popularCategory) => {
        let cat_index = state.popularCategories.findIndex(item => item.id === popularCategory.id)
        Object.assign(state.popularCategories[cat_index], popularCategory)
    },

}

export default {
    state,
    getters,
    mutations,
    actions
}
