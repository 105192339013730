import axios from "axios";
const { baseURL } = require("@/assets/js/constant");
const $http = axios.create({
  baseURL,
});
const state = {
  solarChakiData: [],
  gridData: [],
};

const getters = {
  getSolarChakiData(state) {
    return state.solarChakiData;
  },
  getGridDatas(state) {
    return state.gridData;
  },
};

const actions = {
  async updateCurrentDieselPrice({ commit }, data) {
    console.log(data);
    const event_formdata = new FormData();
    for (const key in data) {
      event_formdata.append(key, data[key]);
    }
    const extractedData = await $http.post(
      "/updateSolarChakkiData",
      event_formdata
    );
    console.log(extractedData.data);
  },

  async getCurrentDieselPrice({ commit }) {
    try {
      const extractedData = await $http.get("/getSolarChakkiData");
      return Promise.resolve(extractedData.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getSolarChakkiData({ commit }) {
    try {
      const extractedData = await $http.get("/getChakkiSystemData");
      //   return Promise.resolve(extractedData.data);
      //   console.log(extractedData.data);
      commit("SET_SOLARCHAKI_DATA", extractedData.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getGridData({ commit }) {
    try {
      const extractedData = await $http.get("/getOnGridCalculatorData");
      commit("SET_GRIDI_DATA", extractedData.data);
    } catch (error) {
      console.log(error);
    }
  },
  async addSolarChakiData({ commit }, data) {
    const event_formdata = new FormData();
    for (const key in data) {
      event_formdata.append(key, data[key]);
    }
    try {
      const extractedData = await $http.post(
        "/addChakkiSystemData",
        event_formdata
      );
      commit("ADD_SOLARCHAKI_DATA", extractedData.data);
    } catch (error) {
      console.log(error);
    }
  },
  async addGridSystemData({ commit }, data) {
    const event_formdata = new FormData();
    for (const key in data) {
      event_formdata.append(key, data[key]);
    }
    try {
      const extractedData = await $http.post(
        "/addOnGridCalculatorData",
        event_formdata
      );
      commit("ADD_GRID_DATA", extractedData.data);
    } catch (error) {
      console.log(error);
    }
  },
  async updateSolarChaki({ commit }, data) {
    const event_formdata = new FormData();
    for (const key in data) {
      event_formdata.append(key, data[key]);
    }
    try {
      const extractedData = await $http.post(
        "/updateChakkiSystemData",
        event_formdata
      );
      console.log(extractedData.data);
      commit("UPDATE_SOLARCHAKI_DATA", data);
    } catch (error) {
      console.log(error);
    }
  },
  async updateGridData({ commit }, data) {
    const event_formdata = new FormData();
    for (const key in data) {
      event_formdata.append(key, data[key]);
    }
    try {
      const extractedData = await $http.post(
        "/updateOnGridCalculatorData",
        event_formdata
      );
      console.log(extractedData.data);
      commit("UPDATE_GRID_DATA", data);
    } catch (error) {
      console.log(error);
    }
  },
};

const mutations = {
  ADD_SOLARCHAKI_DATA: (state, data) => {
    state.solarChakiData.push(data);
  },
  SET_SOLARCHAKI_DATA: (state, data) => {
    state.solarChakiData = data;
  },
  SET_GRIDI_DATA: (state, data) => {
    state.gridData = data;
  },
  UPDATE_SOLARCHAKI_DATA: (state, data) => {
    let prod_index = state.solarChakiData.findIndex(
      (item) => item.id === data.id
    );
    Object.assign(state.solarChakiData[prod_index], data);
  },
  UPDATE_GRID_DATA: (state, data) => {
    let prod_index = state.gridData.findIndex((item) => item.id === data.id);
    Object.assign(state.gridData[prod_index], data);
  },
  ADD_GRID_DATA: (state, data) => {
    state.gridData.push(data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
