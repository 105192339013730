import axios from 'axios'
const { baseURL } = require('@/assets/js/constant')
const $http = axios.create({
    baseURL
})
const state = {
    blogs: [],

}

const getters = {
    GetBlogs(state) {
        return state.blogs.reverse()
    }

}

const actions = {
    async fetchAllBlogs({ commit }) {
        const extractedData = await $http.get('/getBlogs');
        commit('SET_BLOGS', extractedData.data)


    },
    async addBlog({ commit }, data) {
        const event_formdata = new FormData()
        for (const key in data) {
            event_formdata.append(key, data[key])
        }
        const extractedData = await $http.post('/addBlog', event_formdata);
        if (extractedData.data.error) {
            throw new Error(extractedData.data.error);
        }
        commit('ADD_BLOG', extractedData.data)
    },
    async deleteBlog({ commit }, id) {
        try {
            await $http.get(`/deleteBlog?blogId=${id}`
            );
            commit('DELETE_BLOG', id)
        } catch (error) {
            console.log(error);
        }
    },
    async updateBlog({ commit }, data) {
        const event_formdata = new FormData()
        for (const key in data) {
            event_formdata.append(key, data[key])
        }
        try {
            const extractedData = await $http.post('/updateBlog', event_formdata);
            if (extractedData.data.error) {
                throw new Error(extractedData.data.error);
            }
            commit('UPDATE_BLOG', extractedData.data)
        } catch (error) {
            console.log(error);
        }

    },
}

const mutations = {
    ADD_BLOG: (state, blog) => {
        state.blogs.push(blog);
    },
    UPDATE_BLOG: (state, blog) => {
        let blog_index = state.blogs.findIndex(item => item.id === blog.id)
        Object.assign(state.blogs[blog_index], blog)
    },
    DELETE_BLOG: (state, id) => {
        const index = state.blogs.findIndex(blog => blog.id == id)
        state.blogs.splice(index, 1)
    },
    SET_BLOGS: (state, blogs) => {
        state.blogs = blogs
    }

}

export default {
    state,
    getters,
    mutations,
    actions
}
