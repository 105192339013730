<template>
  <v-footer id="default-footer" color="transparent" absolute app inset>
    <v-container>
      <v-row align="center">
        <v-spacer class="hidden-sm-and-down" />

        <v-col cols="12" md="auto">
          <div class="body-1 font-weight-light pt-6 pt-md-0 text-center">
            <a
              href="https://www.anyagreenenergy.com/"
              class="text-decoration-none text-uppercase text-caption font-weight-regular"
              rel="noopener"
              target="_blank"
              >&copy; {{ new Date().getFullYear() }}, AnyaGreenEnergy</a
            >
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    links: [
      {
        href: "https://www.anyagreenenergy.com/",
        text: "AnyaGreenEnergy",
      },
      // {
      //   href: "#",
      //   text: "About Us",
      // },
      // {
      //   href: "#",
      //   text: "Blog",
      // },
      // {
      //   href: "#",
      //   text: "Licenses",
      // },
    ],
  }),
};
</script>




<style lang="sass" scoped>
a
  color: inherit !important
</style>
