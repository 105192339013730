import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';

// Loading Plugin
import { LoadingPlugin } from 'vuetify-loading-overlay'

Vue.config.productionTip = false

Vue.use(LoadingPlugin, {
  // props
  spinnerProps: {
    color: "#70e000"
  },
  overlayProps: {
  }
})

// Toasted Plugin
import Toasted from 'vue-toasted'
Vue.use(Toasted)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
