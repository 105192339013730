import axios from 'axios'
const { baseURL } = require('@/assets/js/constant')
const $http = axios.create({
    baseURL
})
const state = {
    reviews: [],

}

const getters = {
    GetReviews(state) {
        return state.reviews
    }

}

const actions = {
    async fetchAllReviews({ commit }) {
        const extractedData = await $http.get('/getReview');
        console.log(extractedData.data)
        commit('SET_REVIEWS', extractedData.data)
    },

    async updateReview({ commit }, {reviewId, isEnable}) {
        try {
            const extractedData = await $http.get(`/updateReview?isEnable=${isEnable}&reviewId=${reviewId}`);
            if (extractedData.data.error) {
                throw new Error(extractedData.data.error);
            }
            commit('UPDATE_REVIEW', extractedData.data)
        } catch (error) {
            console.log(error);
        }

    },
    async deleteCategory({ commit }, id) {
        try {
            await $http.get(`/deleteCategory?catId=${id}`
            );
            commit('DELETE_CATEGORY', id)
        } catch (error) {
            console.log(error);
        }
    },

}

const mutations = {
    ADD_TESTIMONIAL: (state, testimonial) => {
        state.testimonials.push(testimonial);
    },
    SET_REVIEWS: (state, reviews) => {
        state.reviews = reviews
    }, 
    UPDATE_REVIEW: (state, review) => {
        let review_index = state.reviews.findIndex(item => item.id === review.id)
        Object.assign(state.reviews[review_index], review)
    },
    DELETE_CATEGORY: (state, id) => {
        const index = state.testimonials.findIndex(cat => cat.id == id)
        state.testimonials.splice(index, 1)
    },


}

export default {
    state,
    getters,
    mutations,
    actions
}
