<template>
  <section class="mb-12 text-center">
    <!-- <h1 class="mb-2 text-h3">Dashboard</h1> -->

    <!-- <div class="body-2 font-weight-light">Please checkout the</div> -->
    <v-container class="">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="9" md="5">
          <v-img :src="require('@/assets/appLogo.png')" /> </v-col
      ></v-row>

      <v-subheader class="pl-0 mt-12">Update Diesel Price </v-subheader>
      <v-card class="mx-auto pa-8" color="white" width="100%">
        <v-row>
          <v-text-field
            label="Diesel Price"
            outlined
            v-model="dieselPrice"
            dense
            hide-details=""
          ></v-text-field>
          <v-btn
            class="mx-2"
            color="yellow darken-2"
            :disabled="!dieselPrice"
            :loading="currentDieselPriceLoader"
            @click="handleDieselPriceUpdate()"
            >Update</v-btn
          >
        </v-row>
      </v-card>

      <!-- Solar Chakki -->
      <v-subheader class="pl-0 mt-5">Solar Chakki System </v-subheader>
      <v-card class="mx-auto pa-8" color="white" width="100%">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">System Size</th>
                <th class="text-left">Price</th>
                <th class="text-right">Edit</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="solarChakiData in getSolarChakiData"
                :key="solarChakiData.id"
              >
                <td class="text-left">{{ solarChakiData.system_size }}</td>
                <td class="text-left">{{ solarChakiData.price }}</td>
                <td class="text-right">
                  <v-icon
                    color="yellow darken-2"
                    @click="toggleSolarChakiEdit(solarChakiData)"
                  >
                    mdi-pencil
                  </v-icon>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-row class="mt-4">
          <v-text-field
            label="System Size"
            outlined
            dense
            hide-details=""
            v-model="solarChakiSystem.system_size"
          ></v-text-field>
          <v-text-field
            v-model="solarChakiSystem.price"
            label="Price"
            outlined
            dense
            class="ml-2"
            hide-details=""
          ></v-text-field>
          <v-btn
            class="mx-2"
            color="primary"
            :disabled="!solarChakiSystem.system_size || !solarChakiSystem.price"
            :loading="solarChakiSystemLoader"
            @click="handleSolarChakiAdd()"
            >Add</v-btn
          >
        </v-row>
      </v-card>

      <!-- Grid calculator -->
      <v-subheader class="pl-0 mt-5">Grid Calculator </v-subheader>
      <v-card class="mx-auto pa-8" color="white" width="100%">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Minimum Size (kw)</th>
                <th class="text-left">Maximum Size (kw)</th>
                <th class="text-left">Price</th>
                <th class="text-right">Edit</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="gridData in getGridDatas" :key="gridData.id">
                <td class="text-left">{{ gridData.min_size_in_kw }}</td>
                <td class="text-left">{{ gridData.max_size_in_kw }}</td>
                <td class="text-left">{{ gridData.price }}</td>
                <td class="text-right">
                  <v-icon
                    color="yellow darken-2"
                    @click="toggleGridEdit(gridData)"
                  >
                    mdi-pencil
                  </v-icon>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-row class="mt-4">
          <v-text-field
            label="Minimum Size"
            outlined
            dense
            hide-details=""
            v-model="gridCalculator.min_size_in_kw"
          ></v-text-field>
          <v-text-field
            v-model="gridCalculator.max_size_in_kw"
            label="Maximum Size"
            outlined
            dense
            class="ml-2"
            hide-details=""
          ></v-text-field>
          <v-text-field
            v-model="gridCalculator.price"
            label="Price"
            outlined
            dense
            class="ml-2"
            hide-details=""
          ></v-text-field>
          <v-btn
            class="mx-2"
            color="primary"
            :disabled="
              !gridCalculator.min_size_in_kw ||
              !gridCalculator.max_size_in_kw ||
              !gridCalculator.price
            "
            :loading="gridSystemLoader"
            @click="handleGridDataAdd()"
            >Add</v-btn
          >
        </v-row>
      </v-card>
    </v-container>

    <!-- Solar dialog -->
    <v-dialog v-model="isSolarEdit" width="500" persistent>
      <v-card class="pt-2">
        <v-text-field
          label="System Size"
          outlined
          dense
          hide-details=""
          v-model="solarChakiSystem.system_size"
          class="ma-2"
        ></v-text-field>
        <v-text-field
          v-model="solarChakiSystem.price"
          label="Price"
          outlined
          dense
          class="ma-2"
          hide-details=""
        ></v-text-field>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="red" text @click="defaultSolarChakiValue()">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="confirmSolarChakiEdit()"
            :loading="confirmEditSolarChakiLoader"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Grid Data dialog -->
    <v-dialog v-model="isGridEdit" width="500" persistent>
      <v-card class="pt-2">
        <v-text-field
          label="Minimum Size"
          outlined
          dense
          hide-details=""
          v-model="gridCalculator.min_size_in_kw"
          class="ma-2"
        ></v-text-field>
        <v-text-field
          v-model="gridCalculator.max_size_in_kw"
          label="Maximum Size"
          outlined
          dense
          class="ma-2"
          hide-details=""
        ></v-text-field>
        <v-text-field
          v-model="gridCalculator.price"
          label="Price"
          outlined
          dense
          class="ma-2"
          hide-details=""
        ></v-text-field>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="red" text @click="gridCancelButton()"> Cancel </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="confirmGridEdit()"
            :loading="confirmEditGridLoader"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      dieselPrice: "",
      dieselPriceData: null,

      isSolarEdit: false,
      isGridEdit: false,
      currentDieselPriceLoader: false,
      solarChakiSystemLoader: false,
      gridSystemLoader: false,
      confirmEditSolarChakiLoader: false,
      confirmEditGridLoader: false,

      currentSolarChaki: "",
      currentGridData: "",
      solarChakiSystem: {
        system_size: "",
        price: "",
      },
      gridCalculator: {
        min_size_in_kw: "",
        max_size_in_kw: "",
        price: "",
      },
    };
  },
  methods: {
    ...mapActions([
      "getCurrentDieselPrice",
      "updateCurrentDieselPrice",
      "getSolarChakkiData",
      "addSolarChakiData",
      "updateSolarChaki",
      "getGridData",
      "addGridSystemData",
      "updateGridData",
    ]),
    async handleDieselPriceUpdate() {
      this.currentDieselPriceLoader = true;
      try {
        this.dieselPriceData["price"] = this.dieselPrice;
        await this.updateCurrentDieselPrice(this.dieselPriceData);
        this.$toasted.show("Successfully updated", {
          type: "success",
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          icon: "mdi-clipboard-check",
          iconPack: "mdi",
        });
      } catch (error) {
        this.$toasted.show(error, {
          type: "error",
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          icon: "mdi-clipboard-check",
          iconPack: "mdi",
        });
      }
      this.currentDieselPriceLoader = false;
      // this.$vloading.hide();
    },
    async handleSolarChakiAdd() {
      this.solarChakiSystemLoader = true;
      try {
        await this.addSolarChakiData(this.solarChakiSystem);
        this.$toasted.show("Successfully added", {
          type: "success",
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          icon: "mdi-clipboard-check",
          iconPack: "mdi",
        });
      } catch (error) {
        this.$toasted.show(error, {
          type: "error",
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          icon: "mdi-clipboard-check",
          iconPack: "mdi",
        });
      }
      this.solarChakiSystemLoader = false;
      this.solarChakiSystem.system_size = "";
      this.solarChakiSystem.price = "";

      // this.$vloading.hide();
    },
    toggleSolarChakiEdit(data) {
      this.isSolarEdit = true;
      this.currentSolarChaki = data.id;
      this.solarChakiSystem.system_size = data.system_size;
      this.solarChakiSystem.price = data.price;
    },
    async confirmSolarChakiEdit() {
      this.confirmEditSolarChakiLoader = true;
      try {
        this.solarChakiSystem.id = this.currentSolarChaki;
        await this.updateSolarChaki(this.solarChakiSystem);
        this.$toasted.show("Successfully updated", {
          type: "success",
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          icon: "mdi-clipboard-check",
          iconPack: "mdi",
        });
      } catch (error) {
        this.$toasted.show(error, {
          type: "error",
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          icon: "mdi-clipboard-check",
          iconPack: "mdi",
        });
      }
      this.confirmEditSolarChakiLoader = false;
      this.defaultSolarChakiValue();
    },
    defaultSolarChakiValue() {
      this.isSolarEdit = false;
      this.solarChakiSystem.system_size = "";
      this.solarChakiSystem.price = "";
    },
    defaultGridValue() {
      // this.isSolarEdit = false;
      this.gridCalculator.min_size_in_kw = "";
      this.gridCalculator.max_size_in_kw = "";
      this.gridCalculator.price = "";
    },
    async handleGridDataAdd() {
      this.gridSystemLoader = true;
      try {
        await this.addGridSystemData(this.gridCalculator);
        this.$toasted.show("Successfully added", {
          type: "success",
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          icon: "mdi-clipboard-check",
          iconPack: "mdi",
        });
      } catch (error) {
        this.$toasted.show(error, {
          type: "error",
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          icon: "mdi-clipboard-check",
          iconPack: "mdi",
        });
      }
      this.gridSystemLoader = false;
      this.defaultGridValue();
    },
    toggleGridEdit(data) {
      this.isGridEdit = true;
      this.currentGridData = data.id;

      this.gridCalculator.min_size_in_kw = data.min_size_in_kw;
      this.gridCalculator.max_size_in_kw = data.max_size_in_kw;
      this.gridCalculator.price = data.price;
    },
    async confirmGridEdit() {
      this.confirmEditGridLoader = true;
      try {
        this.gridCalculator.id = this.currentGridData;
        await this.updateGridData(this.gridCalculator);
        this.$toasted.show("Successfully updated", {
          type: "success",
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          icon: "mdi-clipboard-check",
          iconPack: "mdi",
        });
      } catch (error) {
        this.$toasted.show(error, {
          type: "error",
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          icon: "mdi-clipboard-check",
          iconPack: "mdi",
        });
      }
      this.confirmEditGridLoader = false;
      this.isGridEdit = false;
      this.defaultGridValue();
    },
    gridCancelButton() {
      this.isGridEdit = false;
      this.defaultGridValue();
    },
  },
  computed: {
    ...mapGetters(["getSolarChakiData", "getGridDatas"]),
  },
  async created() {
    this.$vloading.show();
    try {
      await this.getCurrentDieselPrice().then((res) => {
        this.dieselPrice = res[0].price;
        this.dieselPriceData = res[0];
      });
      await this.getSolarChakkiData();
      await this.getGridData();
    } catch (error) {
      this.$toasted.show(error, {
        type: "error",
        duration: 5000,
        position: "top-center",
        theme: "bubble",
        icon: "mdi-clipboard-check",
        iconPack: "mdi",
      });
    }
    this.$vloading.hide();
  },
};
</script>

<style>
</style>