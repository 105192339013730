import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/sliders',
    name: 'Sliders',
    component: () => import(/* webpackChunkName: "about" */ '../views/Sliders.vue')
  },
  {
    path: '/blogs',
    name: 'Blogs',
    component: () => import(/* webpackChunkName: "about" */ '../views/Blogs.vue')
  },
  {
    path: '/categories',
    name: 'Categories',
    component: () => import(/* webpackChunkName: "about" */ '../views/Categories.vue')
  },
  {
    path: '/products/:catId',
    name: 'Products',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/Products.vue')
  },
  {
    path: '/product/:productId',
    name: 'Product',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/Product.vue')
  },
  {
    path: '/popular_items',
    name: 'PopularItems',
    component: () => import(/* webpackChunkName: "about" */ '../views/PopularItems.vue')
  },
  {
    path: '/testimonials',
    name: 'Testimonials',
    component: () => import(/* webpackChunkName: "about" */ '../views/Testimonials.vue')
  },
  {
    path: '/reviews',
    name: 'Reviews',
    component: () => import(/* webpackChunkName: "about" */ '../views/Reviews.vue')
  },
  {
    path: '/dealership',
    name: 'Delaership',
    component: () => import(/* webpackChunkName: "about" */ '../views/Dealership.vue')
  },
  {
    path: '/orders',
    name: 'Orders',
    component: () => import(/* webpackChunkName: "about" */ '../views/Orders.vue')
  },
  {
    path: '/carts',
    name: 'Carts',
    component: () => import(/* webpackChunkName: "about" */ '../views/Carts.vue')
  },
  {
    path: '*',
    name: 'Error',
    component: () =>
      import('../views/Error.vue')
  
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
