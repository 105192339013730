import axios from "axios";
const { baseURL } = require("@/assets/js/constant");
const $http = axios.create({
  baseURL,
});
const state = {
  appSliders: [],
  webSliders: [],
};

const getters = {
  getAppSliders(state) {
    return state.appSliders;
  },
  getWebSliders(state) {
    return state.webSliders;
  },
};

const actions = {
  async fetchMobileSliders({ commit }) {
    const extractedData = await $http.get("/getSlider");
    commit("SET_MOBILE_SLIDERS", extractedData.data);
  },
  async fetchWebSliders({ commit }) {
    const extractedData = await $http.get("/getWebSlider");
    commit("SET_WEB_SLIDERS", extractedData.data);
  },

  async addMobileSlider({ commit }, data) {
    const event_formdata = new FormData();
    for (const key in data) {
      event_formdata.append(key, data[key]);
    }
    const extractedData = await $http.post("/addSlider", event_formdata);
    if (extractedData.data.error) {
      throw new Error(extractedData.data.error);
    }
    commit("ADD_MOBILE_SLIDER", extractedData.data);
  },
  async addWebSlider({ commit }, data) {
    const event_formdata = new FormData();
    for (const key in data) {
      event_formdata.append(key, data[key]);
    }
    const extractedData = await $http.post("/addWebSlider", event_formdata);
    if (extractedData.data.error) {
      throw new Error(extractedData.data.error);
    }
    commit("ADD_WEB_SLIDER", extractedData.data);
  },
  async updateMobileSlider({ commit }, data) {
    const event_formdata = new FormData();
    for (const key in data) {
      event_formdata.append(key, data[key]);
    }
    try {
      const extractedData = await $http.post("/updateSlider", event_formdata);
      if (extractedData.data.error) {
        throw new Error(extractedData.data.error);
      }
      commit("UPDATE__MOBILE_SLIDER", extractedData.data);
    } catch (error) {
      console.log(error);
    }
  },
  async updateWebSlider({ commit }, data) {
    const event_formdata = new FormData();
    for (const key in data) {
      event_formdata.append(key, data[key]);
    }
    try {
      const extractedData = await $http.post(
        "/updateWebSlider",
        event_formdata
      );
      if (extractedData.data.error) {
        throw new Error(extractedData.data.error);
      }
      commit("UPDATE_WEB_SLIDER", extractedData.data);
    } catch (error) {
      console.log(error);
    }
  },

  async deleteAppSlider({ commit }, id) {
    try {
      await $http.get(`/deleteSlider?sliderId=${id}`);
      commit("DELETE_MOBILE_SLIDER", id);
    } catch (error) {
      console.log(error);
    }
  },
  async deleteWebSlider({ commit }, id) {
    try {
      await $http.get(`/deleteWebSlider?sliderId=${id}`);
      commit("DELETE_WEB_SLIDER", id);
    } catch (error) {
      console.log(error);
    }
  },
};

const mutations = {
  SET_MOBILE_SLIDERS: (state, sliders) => {
    state.appSliders = sliders;
  },
  SET_WEB_SLIDERS: (state, sliders) => {
    state.webSliders = sliders;
  },
  ADD_MOBILE_SLIDER: (state, slider) => {
    state.appSliders.push(slider);
  },
  ADD_WEB_SLIDER: (state, slider) => {
    state.webSliders.push(slider);
  },
  UPDATE__MOBILE_SLIDER: (state, slider) => {
    let slider_index = state.appSliders.findIndex(
      (item) => item.id === slider.id
    );
    Object.assign(state.appSliders[slider_index], slider);
  },
  UPDATE_WEB_SLIDER: (state, slider) => {
    let slider_index = state.webSliders.findIndex(
      (item) => item.id === slider.id
    );
    Object.assign(state.webSliders[slider_index], slider);
  },
  DELETE_MOBILE_SLIDER: (state, id) => {
    const index = state.appSliders.findIndex((slider) => slider.id == id);
    state.appSliders.splice(index, 1);
  },
  DELETE_WEB_SLIDER: (state, id) => {
    const index = state.webSliders.findIndex((slider) => slider.id == id);
    state.webSliders.splice(index, 1);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
