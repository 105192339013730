import Vue from 'vue'
import Vuex from 'vuex'
import sliders from './modules/sliders'
import category from './modules/category'
import blogs from './modules/blogs'
import products from './modules/products'
import popularCategory from './modules/popularCategory'
import cart from './modules/cart'
import order from './modules/order'
import user from './modules/user'
import testimonials from './modules/testimonials'
import reviews from './modules/reviews.js'
import extra from './modules/extra.js'
import dealership from './modules/dealership.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {

    products,
    popularCategory,
    cart,
    order,
    dealership,
    extra,
    reviews,
    testimonials,
    user,
    blogs,
    category,
    sliders,
  }
})
