import axios from 'axios'
const { baseURL } = require('@/assets/js/constant')
const $http = axios.create({
    baseURL
})
const state = {
    carts: [],

}

const getters = {
    GetCarts(state) {
        return state.carts
    }

}

const actions = {
    async fetchAllCarts({ commit }) {
        const extractedData = await $http.get('/getCartData');
        commit('SET_CARTS', extractedData.data)


    // },
    // async addBlog({ commit }, data) {
    //     const event_formdata = new FormData()
    //     for (const key in data) {
    //         event_formdata.append(key, data[key])
    //     }
    //     const extractedData = await $http.post('/addBlog', event_formdata);

    //     commit('ADD_BLOG', extractedData.data)
    // },
    // async deleteBlog({ commit }, id) {
    //     try {
    //         await $http.get(`/deleteBlog?blogId=${id}`
    //         );
    //         commit('DELETE_BLOG', id)
    //     } catch (error) {
    //         console.log(error);
    //     }
    // },
    // async updateBlog({ commit }, data) {
    //     const event_formdata = new FormData()
    //     for (const key in data) {
    //         event_formdata.append(key, data[key])
    //     }
    //     try {
    //         await $http.post('/updateBlog', event_formdata);
    //         // commit('UPDATE_BLOG', data)
    //     } catch (error) {
    //         console.log(error);
    //     }

    },
}

const mutations = {
    // ADD_BLOG: (state, blog) => {
    //     state.blogs.push(blog);
    // },
    // DELETE_BLOG: (state, id) => {
    //     const index = state.blogs.findIndex(blog => blog.id == id)
    //     state.blogs.splice(index, 1)
    // },
    SET_CARTS: (state, carts) => {
        state.carts = carts
    }

}

export default {
    state,
    getters,
    mutations,
    actions
}
