<template>
  <v-app v-if="IsLoggedIn">
    <AppBar />
    <v-main>
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-main>
    <Footer />
  </v-app>
  <v-app v-else>
    <Auth />
  </v-app>
</template>

<script>
import HelloWorld from "./components/HelloWorld";
import AppBar from "./components/AppBar";
import Footer from "./components/Footer";
import Auth from "./views/Auth";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "App",

  components: {
    HelloWorld,
    Footer,
    AppBar,
    Auth,
  },
  computed: {
    ...mapGetters(["IsLoggedIn"]),
  },

  data: () => ({
    //
  }),
};
</script>
